<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Pagination from "@/components/widgets/pagination";

import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";
import Swal from 'sweetalert2'

import store from "@/state/store";
import {apiTermMethods} from "@/state/helpers";
import Loader from "@/components/widgets/loader";

// 검색 기본값
const BASE_SEARCH = {
  page: 1,
}

export default {
  components: { Layout, PageHeader, Pagination, Loader },
  data() {
    return {
      menuId: 500200,
      menuCode: "terms",
      currentPage: 1,
      totalCount: 0,
      listData: [],
      isPageLoading: false,
      search: BASE_SEARCH,
      dismissSecs: 3,
      dismissCountDown: 0,
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    Tablesaw._init();
  },
  beforeRouteUpdate(to, from, next) {
    if(to.fullPath !== from.fullPath) {
      this.initSearch(to.query)
      next();
    }
  },
  methods: {
    ...apiTermMethods,

    onRoutechange() {
      this.initSearch(this.$route.query)
      this.loadData()
    },

    // 데이터 추출
    loadData() {
      let page = this.search.page

      // 검색 조건 결합
      let params = {
        page: page
      };
      // console.log(params);

      this.isPageLoading = true
      store.dispatch("apiTerms/list", { params }).then((result) => {
        this.updateTableData(result.data, result.recordsFiltered)
        
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      })
    },

    // 데이터 가공
    updateTableData(data, total) {
      this.listData = data;
      this.totalCount = total;

      this.$nextTick(function () {
        // Tablesaw가 동적으로 처리되지 않아 별도 처리
        reloadTablesaw(this.$refs.tablesaw)
      })
    },

    // 라우트 변경 ( 브라우져 뒤로가기 구현 )
    changeRouter() {
      let page = this.search.page
      let params = { page: page };

      // 쿼리스트링 결합
      let query = {
        ...params
      };

      // 히스토리 추가
      this.$router.push({ query })
    },

    // 페이징 처리
    changePage(page) {
      if (this.search.page != page) {
        this.currentPage = this.search.page = page
        this.changeRouter()
      }
    },

    // 검색 초기값 설정
    initSearch(routeQuery) {
      this.search = Object.assign({}, {...BASE_SEARCH, ...routeQuery})
      this.search.page = this.search.page * 1
      this.currentPage = this.search.page
    },

    // 검색 실행
    searchData() {
      this.currentPage = this.search.page = 1

      // 라우트가 변경되면 loadData 호출
      this.changeRouter()
    },

    changeSort(event, item){
      if(event.target.value < 0){
        Swal.fire("0보다 커야합니다.", "", "error");
        event.target.value = 0
        return
      }
      let params = {
        'sort' : event.target.value
      }
      let uid = item.uid
      
      store.dispatch("apiTerms/sorting", { uid, params }).then((result) => {
        if(result.success){
          this.showAlert()
        }
      }, () => {      })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
      
      setTimeout(() => {
        this.dismissCountDown = 0
      }, 3000);
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <div class="row">
      <div class="col-lg-12">
        <div class="card min-content">
          <div class="card-body">
            <div class="text-end mb-2">
              <router-link :to="{name: 'terms-create'}">
                <b-button variant="primary" class="ms-2">약관등록</b-button>
              </router-link>
            </div>
            <div>
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="success"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
              >
                정렬이 수정되었습니다.
              </b-alert>
            </div>
            <div>
              <div class="position-relative" style="min-height: 130px;">
                <table ref="tablesaw" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" class="wd-60" data-tablesaw-priority="6">순번</th>
                    <th scope="col" class="wd-80" data-tablesaw-priority="persist">타입/코드</th>
                    <th scope="col" class="text-start" data-tablesaw-priority="persist">제목</th>
                    <th scope="col" class="wd-40" data-tablesaw-priority="persist">필수</th>
                    <th scope="col" class="wd-60" data-tablesaw-priority="3">정렬</th>
                    <th scope="col" class="wd-100" data-tablesaw-priority="5">등록일</th>
                    <th scope="col" class="wd-100" data-tablesaw-priority="4">수정일</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="list in listData" :key="list.id">
                    <td>{{ list.rownum }}</td>
                    <td class="ellipsis"><span>{{ list.raw_type }} / {{ list.raw_code }}</span></td>
                    <td class="text-start ellipsis ellipsis-wide">
                      <router-link :to="{ name: 'terms-modify', params: { uid: list.uid }}">
                        <span>
                            <b-badge variant="primary" class="font-size-11 px-2" v-b-tooltip.hover title="출력중" pill tag="div" v-if="list.raw_status">O</b-badge>
                            <b-badge variant="danger" class="font-size-11 px-2" v-b-tooltip.hover title="미출력" pill tag="div" v-else>X</b-badge>
                            {{ list.raw_title }}
                        </span>
                      </router-link>
                    </td>
                    <td><span :class="{'text-success': list.raw_required, 'text-danger': !list.raw_required }"><strong>{{ list.raw_required ? 'O' : 'X' }}</strong></span></td>
                    <td>
                      <b-form-input
                        type="number"
                        v-model="list.raw_sort"
                        class="text-center"
                        style="min-width:60px;"
                        @blur="changeSort($event, list)"
                      ></b-form-input>
                    </td>
                    <td>
                      {{ list.created_at }}
                    </td>
                    <td>
                      {{ list.updated_at }}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="position-absolute base-list-message pt-5" v-show="!isPageLoading && listData.length === 0">조회된 내역이 없습니다.</div>
                <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-5"></Loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <Pagination :total="totalCount" v-model:page="currentPage" @changePage="changePage($event)"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

